<a [href]="button?.fields?.hyperlink">
  <div class="button" [style.background-color]="button?.fields?.backgroundColor"
    [style.color]="button?.fields?.buttonTextColor" [style.border-color]="button?.fields?.borderColor"
    [style.border-radius.px]="button?.fields?.borderRadius" [style.border-width.px]="button?.fields?.borderThickness"
    [style.padding-top.px]="button?.fields?.verticalPadding ?? 0"
    [style.padding-bottom.px]="button?.fields?.verticalPadding ?? 0"
    [style.padding-right.px]="button?.fields?.horizontalPadding ?? 0"
    [style.padding-left.px]="button?.fields?.horizontalPadding ?? 0">
    {{ button?.fields?.buttonText }}
  </div>
</a>