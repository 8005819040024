import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ISection } from '../../core/models/section.interface';
import { RscServiceService } from '../../core/services/rsc-service.service';

@Component({
  standalone: true,
  selector: 'rsc-grid-section',
  imports: [CommonModule],
  templateUrl: './grid-section.component.html',
  styleUrls: ['./grid-section.component.scss'],
})
export class GridSectionComponent implements OnChanges {
  @Input() section!: ISection;
  items: any[] = [];

  constructor(private _rscService: RscServiceService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section'] && this.section.apiSource) {
      // this._rscService.getBrands$(this.section.apiSource).subscribe((data: any) => {
      // this._rscService.getBrands$().subscribe((data: IBrandListResponse) => {
      //   this.items = data.results;
      // });
    }
  }

  ngOnInit(): void {
    // if (this.section.apiSource) {
    //   this.http.get(this.section.apiSource).subscribe((data: any) => {
    //     this.items = data.items;
    //   });
    // }
  }
  redirectTo(url: string): void {
    window.open(url, '_blank');
  }
}
