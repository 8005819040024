<div class="image-section" [style.margin-left]="section.sideMargins || '0%'"
  [style.height.px]="section.sectionHeight || 'auto'"
  [style.background-image]="section.backgroundImage ? 'url(' + section.backgroundImage + ')' : null"
  [style.margin-right]="section.sideMargins || '0%'" [style.width]="calculateWidth(section.sideMargins)"
  [style.background-color]="section.backgroundColor || null">
  <div class="overlay">
    <h1 *ngIf="section.title" class="title-heading" [style.color]="section.textColor || null"
      [style.--v-align]="mapVerticalAlignment(section.vAlignTitle)"
      [style.--h-align]="mapHorizontalAlignment(section.hAlignTitle)">
      {{ section.title }}
    </h1>
    <p *ngIf="section.description">{{ section.description }}</p>
  </div>
  <div class="d-flex" *ngFor="let button of section.buttonsOfTheSection">
    <rsc-button *ngIf="button" [button]="button"></rsc-button>
  </div>

  <div class="d-flex" *ngFor="let iconCard of section.iconCards">
    <rsc-icon-card *ngIf="iconCard" [element]="iconCard"></rsc-icon-card>
  </div>
</div>