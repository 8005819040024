import { Injectable } from '@angular/core';
import { createClient, EntryCollection } from 'contentful';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private client = createClient({
    space: 'pj5d9edxa4so',
    accessToken: 'BLDdjLtt3_p7T-YtHsHzuGz_sTmu615wlfW5C8cmdTI',
    environment: 'master',
    host: 'cdn.contentful.com',
    timeout: 60000,
  });

  constructor() {}

  // Fetch homepage data
  getHomePage(): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      content_type: 'homePage',
      include: 3,
    });
  }

  // Fetch individual sections
  getSectionById(id: string): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      'sys.id': id,
    });
  }

  getSectionBySlug(slug: string): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      content_type: slug,
    });
  }
}
