<div class="grid-section" [style.background-color]="section.backgroundColor || null">
  <h2 [style.color]="section.textColor || null">{{ section.title }}</h2>
  <h4 [style.color]="section.textColor || null">{{ section.description }}</h4>
  <div class="grid-items">
    <div
      class="grid-item"
      *ngFor="let item of items"
      [style.background-color]="section.card?.['backgroundColor'] || null">
      <img [src]="item.logo" alt="{{ item.name }}" />
      <p>{{ item.name }}</p>
      <button (click)="redirectTo(item.redirectUrl)">Click me</button>
    </div>
  </div>
</div>
