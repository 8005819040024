import { Component } from '@angular/core';
import { HeaderSectionComponent } from '../../standalone/header-section/header-section.component';
import { FooterSectionComponent } from '../../standalone/footer-section/footer-section.component';
import { GridSectionComponent } from '../../standalone/grid-section/grid-section.component';
import { BgImageSectionComponent } from '../../standalone/bg-image-section/bg-image-section.component';
import { HomeService } from '../../core/services/home.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'rsc-home',
  standalone: true,
  imports: [
    HeaderSectionComponent,
    FooterSectionComponent,
    GridSectionComponent,
    BgImageSectionComponent,
    CommonModule,
    FooterSectionComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  homepage!: any; //HomePage;

  constructor(private homeService: HomeService) {}

  ngOnInit(): void {
    this.homeService.getHomePage().then((data) => {
      this.homepage = data;

      console.log('this.homepage', this.homepage);
    });
  }
}
