import { Component, Input } from '@angular/core';
import { ISection } from '../../core/models/section.interface';

@Component({
  selector: 'rsc-footer-section',
  standalone: true,
  imports: [],
  templateUrl: './footer-section.component.html',
  styleUrl: './footer-section.component.scss',
})
export class FooterSectionComponent {
  @Input() section!: ISection; // Section data input
}
