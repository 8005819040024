import createContentfulApi from './create-contentful-api.js';
function create({
  http,
  getGlobalOptions
}, options, makeInnerClient) {
  const client = createContentfulApi({
    http,
    getGlobalOptions
  }, options);
  const response = client ? client : {};
  Object.defineProperty(response, 'withAllLocales', {
    get: () => makeInnerClient(Object.assign(Object.assign({}, options), {
      withAllLocales: true
    }))
  });
  Object.defineProperty(response, 'withoutLinkResolution', {
    get: () => makeInnerClient(Object.assign(Object.assign({}, options), {
      withoutLinkResolution: true
    }))
  });
  Object.defineProperty(response, 'withoutUnresolvableLinks', {
    get: () => makeInnerClient(Object.assign(Object.assign({}, options), {
      withoutUnresolvableLinks: true
    }))
  });
  return Object.create(response);
}
const makeClient = ({
  http,
  getGlobalOptions
}) => {
  function makeInnerClient(options) {
    return create({
      http,
      getGlobalOptions
    }, options, makeInnerClient);
  }
  const client = createContentfulApi({
    http,
    getGlobalOptions
  }, {
    withoutLinkResolution: false,
    withAllLocales: false,
    withoutUnresolvableLinks: false
  });
  return Object.assign(Object.assign({}, client), {
    get withAllLocales() {
      return makeInnerClient({
        withAllLocales: true,
        withoutLinkResolution: false,
        withoutUnresolvableLinks: false
      });
    },
    get withoutLinkResolution() {
      return makeInnerClient({
        withAllLocales: false,
        withoutLinkResolution: true,
        withoutUnresolvableLinks: false
      });
    },
    get withoutUnresolvableLinks() {
      return makeInnerClient({
        withAllLocales: false,
        withoutLinkResolution: false,
        withoutUnresolvableLinks: true
      });
    }
  });
};
export { makeClient };