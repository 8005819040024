import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { IconCardComponent } from '../../shared/components/icon-card/icon-card.component';

@Component({
  selector: 'rsc-bg-image-section',
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconCardComponent],
  templateUrl: './bg-image-section.component.html',
  styleUrls: ['./bg-image-section.component.scss'],
})
export class BgImageSectionComponent {
  @Input() section!: any; // Section data input
  @Input() headerPosition: 'top' | 'middle' | 'bottom' = 'middle'; // Position of the header

  // Calculate the width dynamically based on side margins
  calculateWidth(sideMargins: string | undefined): string {
    if (!sideMargins) {
      return '100%';
    }
    const marginPercentage = +sideMargins;
    const calculatedWidth = 100 - marginPercentage * 2;
    return `${calculatedWidth}%`;
  }
  mapVerticalAlignment(vAlign: string | undefined): string {
    switch (vAlign) {
      case 'top':
        return '10%';
      case 'middle':
        return '50%';
      case 'bottom':
        return '90%';
      default:
        return '50%'; // Default to center
    }
  }

  mapHorizontalAlignment(hAlign: string | undefined): string {
    switch (hAlign) {
      case 'left':
        return '10%';
      case 'center':
        return '50%';
      case 'right':
        return '90%';
      default:
        return '50%'; // Default to center
    }
  }
}
