import { Injectable } from '@angular/core';
import { ISection } from '../models/section.interface';
import { ContentfulService } from './contentful-service.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private contentfulService: ContentfulService) {}

  // Fetch homepage data and map it into Section objects
  async getHomePage(): Promise<ISection[]> {
    const response = await this.contentfulService.getHomePage();

    if (!response || !response.items || response.items.length === 0) {
      throw new Error('No homepage data found');
    }

    const homepageEntry = response.items[0];
    const sections = homepageEntry.fields['sections'] as any[];

    // Map "includes" for easier lookup
    const entries = this.mapIncludes(response.includes?.Entry || []);
    const assets = this.mapIncludes(response.includes?.Asset || []);

    // Map sections to the desired format
    return sections
      .map((sectionLink: any) => {
        const sectionEntry = entries[sectionLink.sys.id];

        if (!sectionEntry) {
          console.warn(`Section not found for ID: ${sectionLink.sys.id}`);
          return null;
        }

        // Map the section fields
        const section: any = {
          id: sectionEntry.sys.id,
          sectionType: sectionEntry.sys.contentType.sys.id, // e.g., 'headerSection', 'imageSection', etc.
          ...sectionEntry.fields,
          sidePadding: homepageEntry.fields['sidePadding'],
        };

        // Resolve assets (e.g., backgroundImage)
        if (section.backgroundImage?.sys?.id) {
          const asset = assets[section.backgroundImage.sys.id];
          section.backgroundImage = asset?.fields.file.url
            ? `https:${asset.fields.file.url}`
            : null;
        }
        if (section.logo?.sys?.id) {
          const asset = assets[section.logo.sys.id];
          section.logoUrl = section?.logo.fields?.imageUrl?.fields?.file?.url
            ? `https:${section.logo.fields?.imageUrl?.fields?.file?.url}`
            : null;
        }

        // Resolve brandCard
        if (section.brandCard?.sys?.id) {
          const brandCardEntry = entries[section.brandCard.sys.id];
          if (brandCardEntry) {
            section.card = {
              id: brandCardEntry.sys.id,
              ...brandCardEntry.fields, // Include all fields of the brandCard
            };
          } else {
            console.warn(`BrandCard not found for ID: ${section.brandCard.sys.id}`);
          }
        }

        return section;
      })
      .filter((section) => section !== null); // Filter out any null sections
  }

  // Helper to map includes into an object for quick lookups
  private mapIncludes(includes: any[]): Record<string, any> {
    return includes.reduce((acc, include) => {
      acc[include.sys.id] = include;
      return acc;
    }, {});
  }
}
