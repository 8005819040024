<div
  class="footer-wrap"
  [style.background-color]="section.backgroundColor || null"
  data-id="bcc5fb7"
  data-element_type="container">
  <div
    class="elementor-element elementor-element-a14afb1 elementor-widget elementor-widget-text-editor"
    data-id="a14afb1"
    data-element_type="widget"
    data-widget_type="text-editor.default">
    <div class="elementor-widget-container">
      {{ '@2020-2024 Product DNA ALL RIGHTS RESERVED' }}
    </div>
  </div>
</div>
