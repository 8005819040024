import resolveResponse from 'contentful-resolve-response';
import mixinStringifySafe from '../mixins/stringify-safe.js';
function resolveCircular(data, {
  resolveLinks,
  removeUnresolved
}) {
  const wrappedData = mixinStringifySafe(data);
  if (resolveLinks) {
    wrappedData.items = resolveResponse(wrappedData, {
      removeUnresolved,
      itemEntryPoints: ['fields']
    });
  }
  return wrappedData;
}
export { resolveCircular as default };