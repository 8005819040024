export const environment = {
  production: true,
  // Production;
  apiUrl: 'https://backoffice.respectsaas.com',
  // QA
  // apiUrl: 'https://backsass.respect-code.org',
  // DEV
  // apiUrl: 'https://snowflake-backoffice.respect-code.org',
  baseApiUrl: 'https://snowflake-backoffice.respect-code.org/',
  assetPath: '../assets/',
  mapboxToken:
    'pk.eyJ1IjoicHJvZHVjdGRuYSIsImEiOiJja2huaWpjMWIwMjFyMzBwZW1wbnU4MzRuIn0.OC1GTcD3Qj3b70yKiv1l6w',
  mapboxStyle: 'mapbox://styles/productdna/ckvmjtbqg109s14p83e0l5vgr',
  enableSentry: true,
};
