import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';

import { provideHttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

// DO NOT INIT SENTRY ON DEV MODE (development or localhost).
if (environment.enableSentry) {
  Sentry.init({
    dsn: 'https://fde2c8d765a4f7b4fe5a23da96b3b00f@o963956.ingest.us.sentry.io/4508167850754048',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

bootstrapApplication(AppComponent, {
  providers: [provideHttpClient()],
});
