import { Component, Input } from '@angular/core';

@Component({
  selector: 'rsc-icon-card',
  standalone: true,
  imports: [],
  templateUrl: './icon-card.component.html',
  styleUrl: './icon-card.component.scss',
})
export class IconCardComponent {
  @Input() element!: any;
}
