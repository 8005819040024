import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { IBrandListResponse } from '../../standalone/grid-section/grid-section.interface';

@Injectable({
  providedIn: 'root',
})
export class RscServiceService {
  private apiUrl = environment.apiUrl;

  constructor(private _http: HttpClient) {}
  getBrands$(ordering?: string): Observable<IBrandListResponse> {
    if (ordering) {
      const params = new HttpParams().set('ordering', ordering);
      return this._http.get<IBrandListResponse>(`${this.apiUrl}/public/brands/`, {
        params: params,
      });
    }
    return this._http.get<any>(`${this.apiUrl}/en/api/v3/public/brands/`);
  }
  getPlatforms$(ordering?: string): Observable<any> {
    if (ordering) {
      const params = new HttpParams().set('ordering', ordering);
      return this._http.get<any>(`${this.apiUrl}/public/customized_platforms/`, { params: params });
    }
    return this._http.get<any>(`${this.apiUrl}/public/customized_platforms/`);
  }
}
